<template>
  <section class="signup-form">
    <div class="site-inner">
      <div key="step-1" class="row  mb-s">
        <div class="col-13  col-offset-5  col-lg-18  col-lg-offset-3  col-md-22  col-md-offset-1">
          <div v-if="step === 0">
            <h1 class="h2  headline">{{ $t('signUpForm.steps.zero.headline') }}</h1>
            <p class="lead  font-weight-medium  mb-m-more" v-html="$t('signUpForm.steps.zero.text')"></p>

            <div class="" :class="{'display-flex  align-items-center  justify-content-between': !$matchMedia.xs}">
              <div>
                <p class="font-weight-medium  font-size-xs">
                  {{ $t('signUpForm.login.label') }}<br>
                  <router-link :to="{ name: 'Login', params: { lang: $i18n.locale } }" v-slot="{ href, navigate }" class="-thin  -orange">
                    <AnchorLink :href="href" @click.native="navigate">{{ $t('signUpForm.login.link') }}</AnchorLink>
                  </router-link>
                </p>
              </div>
              <div class="display-flex  justify-content-end" :class="{'justify-content-start': $matchMedia.xs}">
                <Button @click.native="step += 1" class="-thin  -orange  mt-xs-xs">{{ $t('signUpForm.button.start.label') }}</Button>
              </div>
            </div>
          </div>
          <div class="lead">
            <div class="mb-s" v-if="step === 1" key="step-1">
              <h2 class="font-weight-normal  subheadline">{{ $t('signUpForm.steps.two.headline') }}</h2>
              <p class="font-weight-medium  subparagraph" v-html="$t('signUpForm.steps.two.text')"></p>
            </div>
            <div class="mb-s" v-if="step === 2" key="step-2">
              <h2 class="font-weight-normal  subheadline">{{ $t('signUpForm.steps.two.headline') }}</h2>
              <p class="font-weight-medium" v-html="$t('signUpForm.steps.two.text')"></p>
            </div>
            <div v-if="submitStatus === 'OK'" key="step-3">
              <h2 class="font-weight-normal  subheadline">{{ $t('signUpForm.steps.three.headline.done') }}</h2>
              <p class="font-weight-medium" v-html="$t('signUpForm.steps.three.text.done')"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-13  col-offset-5  col-lg-18  col-lg-offset-3  col-md-22  col-md-offset-1">
          <FormElement ref="signUpForm"
                       @submit="submitSignup"
                       v-if="!submitStatus || submitStatus === 'PENDING'"
                       key="input"
                       enctype="multipart/form-data">
            <div class="display-flex  mb-m" :style="{'display': submitStatus === 'PENDING' ? 'none' : ''}">
              <ProgressLine :class="{
                'current': step === 1,
                'completed': step > 1}">
              </ProgressLine>
              <ProgressLine :class="{
                'current': step === 2,
                'completed': step > 2,
                'disabled': step < 2 && step !== 0}">
              </ProgressLine>
            </div>
            <transition name="fade">
              <div v-if="step === 1" key="step-1">
                <div class="row  mb-xs">
                  <div class="col-12  col-xs-24">
                    <InputSelect name="formalTitle"
                                 base="signUpForm"
                                 :class="{ '-has-error': $v.newUser.formalTitle.$error }"
                                 :options="$t('signUpForm.inputs.formalTitle.options')"
                                 v-model="$v.newUser.formalTitle.$model">
                      <option value="" disabled selected>
                        {{ $t('signUpForm.inputs.formalTitle.placeholder') }}
                      </option>
                    </InputSelect>
                  </div>
                  <div class="col-12  col-xs-24">
                    <InputSelect name="academicTitle"
                                 base="signUpForm"
                                 :class="{ '-has-error': $v.newUser.academicTitle.$error }"
                                 :options="$t('signUpForm.inputs.academicTitle.options')"
                                 v-model="$v.newUser.academicTitle.$model">
                      <option value="" disabled selected>
                        {{ $t('signUpForm.inputs.academicTitle.placeholder') }}
                      </option>
                    </InputSelect>
                  </div>
                  <div class="col-12 col-xs-24">
                    <InputText name="firstname"
                               base="signUpForm"
                               type="text"
                               :class="{ '-has-error': $v.newUser.firstname.$error }"
                               v-model="$v.newUser.firstname.$model">
                    </InputText>
                  </div>
                  <div class="col-12 col-xs-24">
                    <InputText name="lastname"
                               base="signUpForm"
                               type="text"
                               :class="{ '-has-error': $v.newUser.lastname.$error }"
                               v-model="$v.newUser.lastname.$model">
                    </InputText>
                  </div>
                  <div class="col-8 col-xs-24">
                    <InputText name="zipcode"
                               base="signUpForm"
                               type="text"
                               :class="{ '-has-error': $v.newUser.zipcode.$error }"
                               v-model="$v.newUser.zipcode.$model">
                    </InputText>
                  </div>
                  <div class="col-8 col-xs-24">
                    <InputText name="city"
                               base="signUpForm"
                               type="text"
                               :class="{ '-has-error': $v.newUser.city.$error }"
                               v-model="$v.newUser.city.$model">
                    </InputText>
                  </div>
                  <div class="col-8 col-xs-24">
                    <InputSelect name="country"
                                 base="signUpForm"
                                 :class="{ '-has-error': $v.newUser.country.$error }"
                                 v-model="$v.newUser.country.$model">
                      <template slot="options">
                        <optgroup :label="$t('countries.labels.popular')">
                          <option v-for="(country, key) in $t('countries.popular')" :key="key"
                                  :value="key">
                            {{ country }}
                          </option>
                        </optgroup>
                        <optgroup :label="$t('countries.labels.default')">
                          <option v-for="(country, key) in $t('countries.default')" :key="key"
                                  :value="key">
                            {{ country }}
                          </option>
                        </optgroup>
                      </template>
                    </InputSelect>
                  </div>
                  <div class="col-24">
                    <InputFile name="managerPdf"
                               base="signUpForm"
                               accept=".pdf"
                               :class="{ '-has-error': $v.newUser.managerPdf.$error }"
                               v-model="$v.newUser.managerPdf.$model">
                    </InputFile>
                  </div>
                </div>
                <p class="required-hint">{{ $t('forms.hint.required.label') }}</p>
                <div class="display-flex  justify-content-end">
                  <Button :disabled="enableNextStep"
                          @click.native="step += 1"
                          class="-thin  -orange"
                          :class="{'-full-width  text-center': $matchMedia.xs}">
                    {{ $t('signUpForm.button.next.label') }} 2/2
                  </Button>
                </div>
              </div>
              <div v-if="step === 2" key="step-2">
                <div class="row  mb-xs">
                  <div class="col-24">
                    <InputText name="email"
                               base="signUpForm"
                               type="email"
                               :class="{ '-has-error': $v.newUser.email.$error }"
                               v-model="$v.newUser.email.$model">
                    </InputText>
                  </div>
                  <div class="col-24">
                    <InputText name="password"
                               base="signUpForm"
                               type="password"
                               :class="{ '-has-error': $v.newUser.password.$error }"
                               v-model="$v.newUser.password.$model">
                      <PasswordStrength :pwScore="pwScore"></PasswordStrength>
                      <template v-slot:errors>
                        <template v-if="$v.newUser.password.$error">
                          <template v-if="!$v.newUser.password.required">
                            {{ $t('signUpForm.inputs.password.error.required') }}
                          </template>
                          <template v-else-if="!$v.newUser.password.minLength">
                            {{ $t('signUpForm.inputs.password.error.minLength') }}
                          </template>
                          <template v-else-if="!$v.newUser.password.minPasswordStrength">
                            {{ $t('signUpForm.inputs.password.error.minPasswordStrength') }}
                          </template>
                          <template v-else-if="!$v.newUser.password.maxLength">
                            {{ $t('signUpForm.inputs.password.error.maxLength') }}
                          </template>
                          <template v-else>
                            {{ $t('signUpForm.inputs.password.error.default') }}
                          </template>
                        </template>
                      </template>
                    </InputText>
                  </div>
                  <div class="col-24">
                    <InputText name="confirmPassword"
                               base="signUpForm"
                               type="password"
                               :class="{ '-has-error': $v.newUser.confirmPassword.$error }"
                               v-model="$v.newUser.confirmPassword.$model">
                    </InputText>
                  </div>
                </div>
                <div class="row  mb-s">
                  <div class="col-24">
                    <InputCheckbox name="consentPrivacyAndAgbs"
                                   base="signUpForm"
                                   :class="{ '-has-error': $v.consentPrivacyAndAgbs.$error }"
                                   v-model="$v.consentPrivacyAndAgbs.$model">
                    </InputCheckbox>
                    <p class="required-hint">{{ $t('forms.hint.required.label') }}</p>
                  </div>
                </div>
                <div :class="{'display-flex  align-items-center  justify-content-end': !$matchMedia.xs}">
                  <Button @click.native="step -= 1"
                          class="-thin  -orange  -outlined  mr-s"
                          :class="{'-full-width  text-center': $matchMedia.xs}">
                    {{ $t('signUpForm.button.back.label') }}
                  </Button>
                  <Submit :disabled="enableLastStep"
                          @click.native="submitSignup; step += 1"
                          class="-orange"
                          :class="{'-full-width  text-center  mt-xs': $matchMedia.xs}">
                    {{ $t('signUpForm.button.complete.label') }}
                  </Submit>
                </div>
              </div>
              <div v-if="submitStatus === 'OK'" key="step-3">
                <h2 class="h2">{{ $t('signUpForm.steps.three.headline.next') }}</h2>
                <ul class="font-weight-medium  mb-s  font-size-l" v-html="$t('signUpForm.steps.three.text.next')"></ul>
              </div>
            </transition>
            <p v-if="submitStatus === 'PENDING'">{{ $t('status.submit.loading') }}...</p>
          </FormElement>
          <div class="_result  -success" v-if="submitStatus === 'OK'" key="success">
            <div class="row">
              <div class="col-24">
                <div class="display-flex  mb-m">
                  <ProgressLine class="completed"></ProgressLine>
                  <ProgressLine class="completed"></ProgressLine>
                </div>
                <h4 class="h4  font-weight-normal  subheadline">
                  {{ $t('signUpForm.result.success.title') }}
                </h4>
                <ul class="list">
                  <li v-for="step in successResultSteps" :key="step" v-html="step"></li>
                </ul>
                <p class="info">
                  {{$t('signUpForm.result.success.info')}}
                </p>
              </div>
            </div>
          </div>
          <div class="_result  -error" v-if="submitStatus === 'ERROR' && errorKey === 'default'" key="errorDefault">
            <div class="row">
              <div class="col-24">
                <h6 class="h4">
                  {{ $t('signUpForm.result.error.default.title') }}
                </h6>
                <p>
                  {{ $t('signUpForm.result.error.default.paragraph') }}
                </p>
                <router-link :to="{ name: 'Signup', params: { lang: $i18n.locale } }" v-slot="{ href, navigate }" class="-thin  -orange">
                  <AnchorLink :href="href" @click.native="navigate">{{ $t('signUpForm.result.error.default.link') }}</AnchorLink>
                </router-link>
              </div>
            </div>
          </div>
          <div class="_result  -error" v-if="submitStatus === 'ERROR' && errorKey === 'notUnique'" key="errorNotUnique">
            <div class="row">
              <div class="col-24">
                <h6 class="h4">
                  {{ $t('signUpForm.result.error.notUnique.title') }}
                </h6>
                <p>
                  {{ $t('signUpForm.result.error.notUnique.paragraph') }}
                </p>
                <router-link :to="{ name: 'Login', params: { lang: $i18n.locale } }" v-slot="{ href, navigate }" class="-thin  -orange">
                  <AnchorLink :href="href" @click.native="navigate">{{ $t('signUpForm.result.error.notUnique.link') }}</AnchorLink>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  sameAs,
} from 'vuelidate/lib/validators';
import { signUp } from '@/api/auth.api';
import pwScore from '@/mixins/pwScore';

import Submit from '@/components/atoms/Submit.vue';
import Button from '@/components/atoms/Button.vue';
import AnchorLink from '@/components/atoms/Link.vue';
import FormElement from '@/components/elements/FormElement.vue';
import InputText from '@/components/elements/inputs/InputText.vue';
import InputCheckbox from '@/components/elements/inputs/InputCheckbox.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';
import InputFile from '@/components/elements/inputs/InputFile.vue';
import ProgressLine from '@/components/elements/ProgressLine.vue';
import PasswordStrength from '@/components/elements/PasswordStrength.vue';

const validCity = (value) => new RegExp("^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$", 'gi').test(value);
const validZipcodeLength = (value, vm) => {
  const allowedLengths = {
    DE: 5,
    AT: 4,
    CH: 4,
  };
  const testLength = allowedLengths[vm.country];
  return (testLength) ? value.toString().length === testLength : true;
};

export default {
  name: 'SignupForm',
  mixins: [validationMixin, pwScore],
  data() {
    return {
      step: 0,
      consentPrivacyAndAgbs: false,
      newUser: {
        email: '',
        password: '',
        confirmPassword: '',
        formalTitle: '',
        academicTitle: 'none',
        firstname: '',
        lastname: '',
        zipcode: '',
        city: '',
        country: 'DE',
        managerPdf: null,
        consentAgb: this.consentPrivacyAndAgbs,
        consentPrivacy: this.consentPrivacyAndAgbs,
      },
      submitStatus: false,
      errorKey: 'default',
    };
  },
  watch: {
    consentPrivacyAndAgbs(newValue) {
      this.newUser.consentAgb = newValue;
      this.newUser.consentPrivacy = newValue;
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    successResultSteps() {
      return Object.values(this.$t('signUpForm.result.success.steps'));
    },
    enableNextStep() {
      return this.$v.newUser.firstname.$invalid
      || this.$v.newUser.lastname.$invalid
      || this.$v.newUser.zipcode.$invalid
      || this.$v.newUser.city.$invalid
      || this.$v.newUser.country.$invalid
      || this.$v.newUser.managerPdf.$invalid
      || this.$v.newUser.formalTitle.$invalid;
    },
    enableLastStep() {
      return this.$v.newUser.email.$invalid
      || this.$v.newUser.password.$invalid
      || this.$v.newUser.confirmPassword.$invalid
      || this.$v.consentPrivacyAndAgbs.$invalid;
    },
  },
  validations: {
    consentPrivacyAndAgbs: { sameAs: sameAs(() => true) },
    newUser: {
      email: { required, email },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(32),
        minPasswordStrength: function minPwStr(password) {
          return this.minPasswordStrength(password);
        },
      },
      confirmPassword: { required, sameAs: sameAs('password') },
      formalTitle: { required },
      academicTitle: {},
      firstname: { required },
      lastname: { required },
      zipcode: { required, numeric, validZipcodeLength },
      city: { required, validCity },
      country: { required },
      managerPdf: { required },
      consentPrivacy: { sameAs: sameAs(() => true) },
      consentAgb: { sameAs: sameAs(() => true) },
    },
  },
  methods: {
    async submitSignup() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.submitStatus = 'PENDING';

        const formData = new FormData();

        Object.keys(this.newUser).forEach((key) => {
          formData.append(key, this.newUser[key]);
        });

        try {
          await signUp(formData);
          this.submitStatus = 'OK';
        } catch (error) {
          this.submitStatus = 'ERROR';
          console.log(error.response);
          if (error.response.status === 409 && error.response.data.message === 'notUnique') {
            this.errorKey = 'notUnique';
          }
        }
      }
    },
  },
  components: {
    Submit,
    FormElement,
    InputText,
    InputCheckbox,
    InputSelect,
    ProgressLine,
    Button,
    AnchorLink,
    InputFile,
    PasswordStrength,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/signup-form.scss"></style>
