<template>
  <div class="row" :class="{'display-flex  -full-width  full-height': !$matchMedia.sm}">
    <div class="col-14  col-sm-24  col-xs-24  text-column">
      <slot name="text-content"></slot>
    </div>
    <div v-if="!$matchMedia.sm" class="col-10  col-sm-24  image-column" :style="{backgroundImage: 'url('+require('@/assets/graphics/'+image+'.jpg')+')'}">
      <slot name="image-content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwoColumns',
  props: {
    image: String,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/two-columns.scss"></style>
