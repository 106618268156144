<template>
  <div class="site">
    <HeaderBarPublic></HeaderBarPublic>
    <main class="signup-verify">
      <div v-if="$matchMedia.sm"
           :style="{marginTop: '-40px',height: '40vh', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', width: '100%',
           backgroundImage: 'url('+require('@/assets/graphics/registration.jpg')+')'}"></div>
      <TwoColumns image="registration">
        <template v-slot:text-content>
          <div class="col-13  col-offset-5  col-lg-18  col-lg-offset-3  col-md-22  col-md-offset-1  col-sm-24  col-sm-offset-0">
            <div v-if="verifyStatus === 'OK'">
              <h2>{{ $t('signUpForm.steps.zero.headline') }}</h2>
              <p v-html="$t('signUpForm.verify.redirect.counter', [redirectTimer])"></p>
              <p>
                {{ $t('signUpForm.verify.redirect.notWorking') }}<br>
                <router-link :to="{ name: 'Login', params: { lang: $i18n.locale } }" v-slot="{ href }" class="-thin  -orange">
                  <AnchorLink :href="href">{{ $t('signUpForm.verify.redirect.toLogin') }}</AnchorLink>
                </router-link>
              </p>
            </div>
            <div v-if="verifyStatus === 'ERROR'">
              <h4>{{ $t('error.signupVerify.label') }}</h4>
              <p>
                <i18n path="signUpForm.verify.tryAgain.label">
                  <router-link :to="{ name: 'Signup', params: { lang: $i18n.locale } }">{{ $t('signUpForm.verify.tryAgain.signup') }}</router-link>
                </i18n>
              </p>
            </div>
          </div>
        </template>
        <template v-slot:image-content></template>
      </TwoColumns>
    </main>
  </div>
</template>

<script>
import { verify } from '@/api/auth.api';
import AnchorLink from '@/components/atoms/Link.vue';
import TwoColumns from '@/components/elements/TwoColumns.vue';
import HeaderBarPublic from '@/components/modules/HeaderBarPublic.vue';

export default {
  name: 'SignupVerify',
  props: {
    token: String,
  },
  data() {
    return {
      verifyStatus: false,
      redirectTimer: 3,
    };
  },
  mounted() {
    if (this.token) {
      verify(this.token)
        .then(() => {
          this.verifyStatus = 'OK';
          this.redirectTimeout();
        })
        .catch((error) => {
          console.error(error);
          this.verifyStatus = 'ERROR';
        });
    } else {
      this.verifyStatus = 'ERROR';
    }
  },
  methods: {
    redirectTimeout() {
      setTimeout(() => {
        if (this.redirectTimer > 1) {
          this.redirectTimer -= 1;
          this.redirectTimeout();
        } else {
          this.$router.push({ name: 'Login', params: { lang: this.$i18n.locale } });
        }
      }, 1000);
    },
  },
  components: {
    TwoColumns,
    AnchorLink,
    HeaderBarPublic,
  },
};
</script>
