var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"public-header-bar"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4  col-xs-12"},[_c('router-link',{attrs:{"to":{ name: 'Home', params: { lang: _vm.$i18n.locale } }}},[_c('Logo',[_c('LogoSVG',{staticClass:"color-white"})],1)],1)],1),(_vm.$matchMedia.xs)?_c('div',{staticClass:"col-xs-12  text-right"},[_c('nav',{staticClass:"meta-nav"},_vm._l((_vm.langs),function(lang){return _c('a',{key:lang,staticClass:"meta-link",class:{ 'is-active': _vm.$i18n.locale === lang },attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.changeLocale(lang)}}},[_c('span',[_vm._v(" "+_vm._s(lang.toUpperCase())+" ")])])}),0)]):_vm._e(),_c('div',{staticClass:"col-20  col-xs-24  valign-middle",class:{'text-right': !_vm.$matchMedia.xs}},[(!_vm.$matchMedia.xs)?_c('nav',{staticClass:"meta-nav"},_vm._l((_vm.langs),function(lang){return _c('a',{key:lang,staticClass:"meta-link",class:{ 'is-active': _vm.$i18n.locale === lang },attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.changeLocale(lang)}}},[_c('span',[_vm._v(" "+_vm._s(lang.toUpperCase())+" ")])])}),0):_vm._e(),(!_vm.$matchMedia.xs)?_c('nav',{staticClass:"main-nav"},[(this.$route.name === 'Signup')?_c('router-link',{attrs:{"to":{ name: 'Login', params: { lang: _vm.$i18n.locale } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('Button',{staticClass:"-white  -outlined",attrs:{"href":href},nativeOn:{"click":function($event){return navigate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('headerBar.buttons.login.label')))])]}}],null,false,1621205236)}):_vm._e(),(this.$route.name === 'Login')?_c('router-link',{attrs:{"to":{ name: 'Signup', params: { lang: _vm.$i18n.locale } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('Button',{staticClass:"-white  -outlined",attrs:{"href":href},nativeOn:{"click":function($event){return navigate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('headerBar.buttons.signup.label')))])]}}],null,false,2780033313)}):_vm._e(),(this.$route.path.includes('public'))?_c('div',[_c('router-link',{attrs:{"to":{ name: 'Login', params: { lang: _vm.$i18n.locale } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('Button',{staticClass:"-white  -outlined  mb-xs-xs  mr-xs-xs",attrs:{"href":href},nativeOn:{"click":function($event){return navigate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('headerBar.buttons.login.label')))])]}}],null,false,3134638820)}),_c('router-link',{staticClass:"ml-xs  ml-xs-0",attrs:{"to":{ name: 'Signup', params: { lang: _vm.$i18n.locale } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('Button',{staticClass:"-orange",attrs:{"href":href},nativeOn:{"click":function($event){return navigate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('headerBar.buttons.signup.label')))])]}}],null,false,2174116767)})],1):_vm._e()],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }