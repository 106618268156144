<template>
  <div class="site">
    <HeaderBarPublic></HeaderBarPublic>
    <main class="signup">
      <div v-if="$matchMedia.sm"
           :style="{marginTop: '-40px',height: '40vh', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', width: '100%',
           backgroundImage: 'url('+require('@/assets/graphics/registration.jpg')+')'}"></div>
      <TwoColumns image="registration">
        <template v-slot:text-content>
          <SignupForm></SignupForm>
        </template>
        <template v-slot:image-content></template>
      </TwoColumns>
    </main>
  </div>
</template>

<script>
import TwoColumns from '@/components/elements/TwoColumns.vue';
import SignupForm from '@/components/modules/SignupForm.vue';
import HeaderBarPublic from '@/components/modules/HeaderBarPublic.vue';

export default {
  name: 'Signup',
  components: {
    TwoColumns,
    SignupForm,
    HeaderBarPublic,
  },
  metaInfo() {
    return {
      title: this.$t('metaData.signup.title'),
    };
  },
};
</script>
