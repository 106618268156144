<template>
  <header class="public-header-bar">
    <div class="row">
      <div class="col-4  col-xs-12">
        <router-link :to="{ name: 'Home', params: { lang: $i18n.locale } }">
          <Logo>
            <LogoSVG class="color-white"></LogoSVG>
          </Logo>
        </router-link>
      </div>
      <div v-if="$matchMedia.xs" class="col-xs-12  text-right">
        <nav class="meta-nav">
          <a href="" v-for="lang in langs" :key="lang"
             class="meta-link"
             :class="{ 'is-active': $i18n.locale === lang }"
             @click.prevent="changeLocale(lang)">
            <span>
              {{ lang.toUpperCase() }}
            </span>
          </a>
        </nav>
      </div>
      <div class="col-20  col-xs-24  valign-middle" :class="{'text-right': !$matchMedia.xs}">
        <nav v-if="!$matchMedia.xs" class="meta-nav">
          <a href="" v-for="lang in langs" :key="lang"
             class="meta-link"
             :class="{ 'is-active': $i18n.locale === lang }"
             @click.prevent="changeLocale(lang)">
            <span>
              {{ lang.toUpperCase() }}
            </span>
          </a>
        </nav>
        <nav class="main-nav" v-if="!$matchMedia.xs">
          <router-link :to="{ name: 'Login', params: { lang: $i18n.locale } }" v-slot="{ href, navigate }" v-if="this.$route.name === 'Signup'">
            <Button :href="href" @click.native="navigate" class="-white  -outlined">{{ $t('headerBar.buttons.login.label') }}</Button>
          </router-link>
          <router-link :to="{ name: 'Signup', params: { lang: $i18n.locale } }" v-slot="{ href, navigate }" v-if="this.$route.name === 'Login'">
            <Button :href="href" @click.native="navigate" class="-white  -outlined">{{ $t('headerBar.buttons.signup.label') }}</Button>
          </router-link>
          <div v-if="this.$route.path.includes('public')">
            <router-link :to="{ name: 'Login', params: { lang: $i18n.locale } }" v-slot="{ href, navigate }">
              <Button :href="href" @click.native="navigate" class="-white  -outlined  mb-xs-xs  mr-xs-xs">{{ $t('headerBar.buttons.login.label') }}</Button>
            </router-link>
            <router-link  class="ml-xs  ml-xs-0" :to="{ name: 'Signup', params: { lang: $i18n.locale } }" v-slot="{ href, navigate }">
              <Button :href="href" @click.native="navigate" class="-orange">{{ $t('headerBar.buttons.signup.label') }}</Button>
            </router-link>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { LANGS } from '@/constants';

import Button from '@/components/atoms/Button.vue';
import Logo from '@/components/atoms/Logo.vue';
import LogoSVG from '@/assets/logo.svg';

export default {
  name: 'HeaderBarPublic',
  computed: {
    langs() {
      return LANGS;
    },
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.push(`/${locale}${this.$router.currentRoute.path.substr(3)}`);
    },
  },
  components: {
    Button,
    Logo,
    LogoSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/public-header-bar.scss"></style>
